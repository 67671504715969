<template>
  <a-modal
      :title="title + '费用明细'"
      :width="1110"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :destroyOnClose="true"
      :centered="true"
      :footer="null"
      @cancel="cancel"
  >
    <template v-if="bill_type===1">
      <a-spin :spinning="allMonthLoading">
        <div class="table">
          <div>
            <div>
              <a-row>
                <a-col :span="12">
                  <b>合计：</b>
                </a-col>
                <a-col :span="12" style="text-align: right">
                  <a v-action:log_export @click="exportToExcel('allMonthList')">导出为Excel</a>
                </a-col>
              </a-row>
            </div>
            <div>
              <a-tag>总营收：<span style="color: red">￥</span>{{ allMonthTotal.total_revenue }}</a-tag>
              <a-tag>总开支：<span style="color: red">￥</span>{{ allMonthTotal.total_expenditure }}</a-tag>
              <a-tag>利润：<span style="color: red">￥</span>{{ allMonthTotal.profit }}</a-tag>
            </div>
          </div>
          <div style="margin-top: 10px">
            <b>明细记录：</b>
            <table border style="text-align: center;table-layout:fixed;">
              <tr>
                <td style="width: 100px;padding: 5px" class="title">月份</td>
                <td class="title" style="width:200px">车辆名称</td>
                <td class="title" style="width:160px">总营收</td>
                <td class="title" style="width:80px">总开支</td>
                <td class="title" style="width:80px">利润</td>
              </tr>
              <div v-if="allMonthList.length===0">
                <a-empty/>
              </div>
              <tr v-for="item in allMonthList">
                <td style="width: 100px;text-align: center">{{ item.month_str }}</td>
                <td style="width:200px">{{ item.vehicle_name }}</td>
                <td style="width:100px"><span style="color: red">￥</span>{{ item.total_revenue }}</td>
                <td style="width:100px"><span style="color: red">￥</span>{{ item.total_expenditure }}</td>
                <td style="width:100px"><span style="color: red">￥</span>{{ item.profit }}</td>
              </tr>

              <tr>
                <td style="width: 100px;padding: 5px" class="title">合计</td>
                <td class="title" style="width:200px">-</td>
                <td class="title" style="width:160px">{{ allMonthTotal.total_revenue }}</td>
                <td class="title" style="width:100px">{{ allMonthTotal.total_expenditure }}</td>
                <td class="title" style="width:100px">{{ allMonthTotal.profit }}</td>
              </tr>
            </table>
          </div>
        </div>
      </a-spin>
    </template>
    <template v-if="bill_type===0">
      <div class="table-operator">
        <a-button v-action:log_export type="primary" @click="exportToExcel" style="margin-right: 10px">导出为Excel表格
        </a-button>
        <a-button @click="getData">刷新</a-button>
      </div>
      <a-spin :spinning="totalRevenueLoading">
        <div class="table">
          <div>
            <div>
              <a-row>
                <a-col :span="12">
                  <b>营收合计（<span style="color: red">￥</span>{{ revenueTotal.xxxxx_income || 0 }}）：</b>
                </a-col>
                <a-col :span="12" style="text-align: right">
                  <a
                      @click="showTotalRevenueLog=!showTotalRevenueLog">{{
                      showTotalRevenueLog ? '收起明细' : '展开明细'
                    }}</a>
                </a-col>
              </a-row>
            </div>
            <div>
              <a-tag>装货吨位：<span style="color: red">￥</span>{{ revenueTotal.zhsl }}</a-tag>
              <a-tag>卸货吨位：<span style="color: red">￥</span>{{ revenueTotal.xhsl }}</a-tag>
              <a-tag>损耗量：<span style="color: red">￥</span>{{ revenueTotal.xxxxx_loss }}</a-tag>
              <a-tag>实结数：<span style="color: red">￥</span>{{ revenueTotal.xhsl }}</a-tag>
              <a-tag>收入：<span style="color: red">￥</span>{{ revenueTotal.xxxxx_income }}</a-tag>
            </div>
          </div>
          <div style="margin-top: 10px" v-if="showTotalRevenueLog">
            <b>明细记录：</b>
            <table border style="text-align: center;table-layout:fixed;">
              <tr>
                <td style="width: 100px;padding: 5px" class="title">完成日期</td>
                <td class="title" style="width:200px">起讫地</td>
                <td class="title" style="width:160px">品名</td>
                <td class="title" style="width:80px">装货吨位</td>
                <td class="title" style="width:80px">卸货吨位</td>
                <td class="title" style="width:80px">损耗量</td>
                <td class="title" style="width:80px">实结数</td>
                <td class="title" style="width:100px">单价</td>
                <td class="title" style="width:100px">税金</td>
                <td class="title" style="width:100px">收入</td>
                <td class="title" style="width:100px">驾驶员</td>
                <td class="title" style="width:100px">押运员</td>
              </tr>
              <div v-if="revenueList.length===0">
                <a-empty/>
              </div>
              <tr v-for="item in revenueList">
                <td style="width: 100px;text-align: center">{{ item.complete_times }}</td>
                <td style="width:200px">{{ item.xxxxx_qsd }}</td>
                <td style="width:160px">{{ item.xxxxx_pm }}</td>
                <td style="width:100px"><a @click="editOvNumber(item,'zhsl')">{{ item.zhsl }}吨</a></td>
                <td style="width:100px"><a @click="editOvNumber(item,'xhsl')">{{ item.xhsl }}吨</a></td>
                <td style="width:100px">{{ item.xxxxx_loss }}</td>
                <td style="width:100px">{{ item.xhsl }}吨</td>
                <td style="width:100px"><a @click="editOvPrice(item)"><span
                    style="color: red">￥</span>{{ item.xxxxx_price }}</a></td>
                <td style="width:100px"><span style="color: red">￥</span>{{ item.xxxxx_taxes }}</td>
                <td style="width:100px"><span style="color: red">￥</span>{{ item.xxxxx_income }}</td>
                <td style="width:100px">{{ item.driver ? item.driver.personnel_name : '' }}</td>
                <td style="width:100px">{{ item.supercargo ? item.supercargo.personnel_name : '' }}</td>
              </tr>
              <tr>
                <td style="width: 100px;padding: 5px" class="title">合计</td>
                <td class="title" style="width:200px">-</td>
                <td class="title" style="width:160px">-</td>
                <td class="title" style="width:100px">{{ revenueTotal.zhsl }}吨</td>
                <td class="title" style="width:100px">{{ revenueTotal.xhsl }}吨</td>
                <td class="title" style="width:100px">{{ revenueTotal.xxxxx_loss }}</td>
                <td class="title" style="width:100px">{{ revenueTotal.xhsl }}吨</td>
                <td class="title" style="width:100px">{{ revenueTotal.xxxxx_price }}</td>
                <td class="title" style="width:100px">{{ revenueTotal.xxxxx_taxes }}</td>
                <td class="title" style="width:100px">{{ revenueTotal.xxxxx_income }}</td>
                <td class="title" style="width:100px">-</td>
                <td class="title" style="width:100px">-</td>
              </tr>
            </table>
          </div>
        </div>
      </a-spin>
      <a-spin :spinning="totalCoseLoading">
        <div class="table">
          <div>
            <div>
              <a-row>
                <a-col :span="12">
                  <b>成本合计（<span style="color: red">￥</span>{{ (coseTotal.total || 0) }}）：</b>
                </a-col>
                <a-col :span="12" style="text-align: right">
                  <a
                      @click="showTotalCoseLog=!showTotalCoseLog">{{ showTotalCoseLog ? '收起明细' : '展开明细' }}</a>
                </a-col>
              </a-row>
            </div>
            <div>
              <a-tag>加油费用：<span style="color: red">￥</span>{{ coseTotal.ck_refuel_amount }}</a-tag>
              <!--              <a-tag>卡、现金加油费用：<span style="color: red">￥</span>{{ coseTotal.ykxj_refuel_amount }}</a-tag>-->
              <a-tag>保养费用：<span style="color: red">￥</span>{{ coseTotal.maintenance_amount }}</a-tag>
              <a-tag>轮胎费用：<span style="color: red">￥</span>{{ coseTotal.tire_amount }}</a-tag>
              <a-tag>尿素费用：<span style="color: red">￥</span>{{ coseTotal.urea_amount }}</a-tag>
              <a-tag>已申领工具费用：<span style="color: red">￥</span>{{ coseTotal.tool_amount }}</a-tag>
              <a-tag>装车/磅费：<span style="color: red">￥</span>{{ coseTotal.ov_zcbf }}</a-tag>
              <a-tag>修车/洗车费用：<span style="color: red">￥</span>{{ coseTotal.ov_xcxc }}</a-tag>
              <a-tag>其他费用：<span style="color: red">￥</span>{{ coseTotal.ov_qt }}</a-tag>
              <a-tag>驾驶员工资：<span style="color: red">￥</span>{{ coseTotal.driver_salary }}</a-tag>
              <a-tag>押运员工资：<span style="color: red">￥</span>{{ coseTotal.supercargo_salary }}</a-tag>
            </div>

          </div>
          <div v-if="!isEmpty(fixed_expenses_list)">
            <b>月固定开支：</b>
            <div>
              <a-tag
                  v-for="item in fixed_expenses_list"
              >{{ item.name }}：<span style="color: red">￥</span>{{ item.amount }}
              </a-tag>
            </div>
          </div>
          <div v-action:log_editewfy>
            <div><b>额外开支：</b><a
                @click="handleEditExtraExpenses">编辑</a>
            </div>
            <div class="tj">
              <template v-if="!isEmpty(extra_expenses_list)">
                <div v-for="item in extra_expenses_list">{{ item.name }}：{{ item.amount }}</div>
              </template>
              <template v-else>无</template>
            </div>
          </div>
          <div v-action:log_editlf>
            <div><b>路费：</b><a
                @click="editTravelAmount">编辑</a>
            </div>
            <div class="tj">
              <span style="color: red">￥</span>{{ travel_amount ? travel_amount : '0.00' }}
            </div>
          </div>

          <div style="margin-top: 10px" v-if="showTotalCoseLog">
            <b>明细记录：</b>
            <table border style="text-align: center;table-layout:fixed;">
              <tr>
                <td style="width: 80px;padding: 5px" class="title">月份</td>
                <td class="title" style="width:80px">加油费用</td>
                <!--                <td class="title" style="width:100px">卡、现金加油费用</td>-->
                <td class="title" style="width:80px">加油公里数</td>
                <td class="title" style="width:80px">保养费用</td>
                <td class="title" style="width:80px">轮胎费用</td>
                <!--                <td class="title" style="width:80px">尿素费用</td>-->
                <td class="title" style="width:80px">已申领工具费用</td>

                <td class="title" style="width:80px">装车/磅费</td>
                <td class="title" style="width:80px">修车/洗车费用</td>
                <td class="title" style="width:80px">其他费用</td>

              </tr>
              <div v-if="list.length===0">
                <a-empty/>
              </div>
              <tr v-for="item in list"
                  v-if="item.ck_refuel_amount||item.ykxj_refuel_amount||item.maintenance_amount||item.tire_amount||item.urea_amount||item.kilometers||item.tool_amount||item.ov_zcbf||item.ov_xcxc||item.ov_qt"
              >
                <td style="width: 100px;text-align: center">{{ item.curr_yearmonth }}</td>
                <td style="width:100px">
                  <a @click="$refs.RefuelLogModel.open({...item, ...queryParam})">
                    <span style="color: red">￥</span>{{ item.ck_refuel_amount }}
                  </a>
                </td>
                <td style="width:100px">{{ item.kilometers }}km</td>
                <td style="width:100px">
                  <a @click="$refs.MaintenanceLogModel.open({...item, ...queryParam})">
                    <span style="color: red">￥</span>{{ item.maintenance_amount }}
                  </a>
                </td>
                <td style="width:100px">
                  <a @click="$refs.TireLogModel.open({...item, ...queryParam})">
                    <span style="color: red">￥</span>{{ item.tire_amount }}
                  </a>
                </td>
                <!--                <td style="width:100px">-->
                <!--                  <a @click="$refs.UreaLogModel.open({...item, ...queryParam})">-->
                <!--                    <span style="color: red">￥</span>{{ item.urea_amount }}-->
                <!--                  </a>-->
                <!--                </td>-->
                <td style="width:100px">
                  <a @click="$refs.TooApproveLogModel.open({...item, ...queryParam})">
                    <span style="color: red">￥</span>{{ item.tool_amount }}
                  </a>
                </td>


                <td style="width:100px">
                  <a @click="$refs.OrderVehicleCostLogModel.open({...item, ...queryParam,cost_type:0})">
                    <span style="color: red">￥</span>{{ item.ov_zcbf }}
                  </a>
                </td>
                <td style="width:100px">
                  <a @click="$refs.OrderVehicleCostLogModel.open({...item, ...queryParam,cost_type:1})">
                    <span style="color: red">￥</span>{{ item.ov_xcxc }}
                  </a>
                </td>
                <td style="width:100px">
                  <a @click="$refs.OrderVehicleCostLogModel.open({...item, ...queryParam,cost_type:2})">
                    <span style="color: red">￥</span>{{ item.ov_qt }}
                  </a>
                </td>
              </tr>

              <tr>
                <td style="width: 100px;padding: 5px" class="title">合计</td>
                <td class="title" style="width:100px">{{ coseTotal.ck_refuel_amount }}</td>
                <!--                <td class="title" style="width:100px">{{ coseTotal.ykxj_refuel_amount }}</td>-->
                <td class="title" style="width:100px">-</td>
                <td class="title" style="width:100px">{{ coseTotal.maintenance_amount }}</td>
                <td class="title" style="width:100px">{{ coseTotal.tire_amount }}</td>
                <!--                <td class="title" style="width:100px">{{ coseTotal.urea_amount }}</td>-->
                <td class="title" style="width:100px">{{ coseTotal.tool_amount }}</td>

                <td class="title" style="width:100px">{{ coseTotal.ov_zcbf }}</td>
                <td class="title" style="width:100px">{{ coseTotal.ov_xcxc }}</td>
                <td class="title" style="width:100px">{{ coseTotal.ov_qt }}</td>
              </tr>
            </table>
          </div>
        </div>
      </a-spin>
      <a-spin :spinning="totalRevenueLoading && totalCoseLoading">
        <div class="table">
          <div>
            <div>
              <a-row>
                <a-col :span="12">
                  <b>利润：<span style="color: red">￥</span>{{
                      Number(((revenueTotal.xxxxx_income || 0) - (coseTotal.total || 0)).toFixed(2))
                    }}</b>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      </a-spin>
      <EditFixedExpenses
          ref="EditFixedExpenses"
          :vehicle_id="queryParam.vehicle_id"
          :vb_id="queryParam.vb_id"
          @handleSubmit="getTotalCost"
      />

      <EditTravelAmountModel
          ref="EditTravelAmountModel"
          :vehicle_id="queryParam.vehicle_id"
          :vb_id="queryParam.vb_id"
          @handleSubmit="getTotalCost"
      />

      <EditOrderVehicle
          ref="EditOrderVehicle"
          @handleSubmit="getTotalRevenue"
      />

      <RefuelLog
          ref="RefuelLogModel"
          @handleSubmit="getTotalCost"
      />
      <MaintenanceLog
          ref="MaintenanceLogModel"
          @handleSubmit="getTotalCost"
      />
      <TireLog
          ref="TireLogModel"
          @handleSubmit="getTotalCost"
      />
      <UreaLog
          ref="UreaLogModel"
          @handleSubmit="getTotalCost"
      />

      <TooApproveLog
          ref="TooApproveLogModel"
          @handleSubmit="getTotalCost"
      />

      <OrderVehicleCostLog
          ref="OrderVehicleCostLogModel"
          @handleSubmit="getTotalCost"
      />

      <EditOrderVehiclePrice
          ref="EditOrderVehiclePriceModel"
          @handleSubmit="getTotalRevenue"
      />
    </template>
  </a-modal>
</template>
<script>
import * as Api from '@/api/statistics/vehicleBill'
import {STable} from "@/components";
import {isEmpty} from "@/utils/util";
import EditFixedExpenses from "./EditFixedExpenses";
import EditTravelAmountModel from "./editTravelAmountModel";
import EditOrderVehicle from "./editOrderVehicle";
import EditOrderVehiclePrice from "./editOrderVehiclePrice";
import RefuelLog from "./refuelLog/Index";
import MaintenanceLog from "./maintenanceLog/Index";
import UreaLog from "./ureaLog/Index";
import TireLog from "./tireLog/Index";
import TooApproveLog from "./tooApproveLog/Index";
import OrderVehicleCostLog from "./OrderVehicleCostLog/Index";
import * as XLSX from 'xlsx';
import XLSXStyle from 'xlsx-style';
import {saveAs} from 'file-saver';

export default {
  components: {
    STable,
    EditFixedExpenses,
    EditTravelAmountModel,
    EditOrderVehicle,
    RefuelLog,
    MaintenanceLog,
    TireLog,
    UreaLog,
    TooApproveLog,
    OrderVehicleCostLog,
    EditOrderVehiclePrice
  },
  data() {
    return {
      title: '',
      queryParam: {
        vehicle_id: '',
        month_time: '',
        vb_id: '',
        bill_type: '',
      },
      visible: false,
      loading: false,

      excelLoading: false,


      fixed_expenses_list: [],
      extra_expenses_list: [],

      //成本费用列表
      list: [],
      coseTotal: {},
      //营收费用列表
      revenueList: [],
      revenueTotal: {},

      allMonthList: [],
      allMonthTotal: {},

      showTotalCoseLog: false,
      showTotalRevenueLog: false,
      totalRevenueLoading: false,
      totalCoseLoading: false,
      allMonthLoading: false,

      bill_type: 0,
      profit: 0,
      travel_amount: 0,
    }
  },
  methods: {
    isEmpty,

    setTableStyle(obj) {
      let borderAll = { // 单元格外侧框线
        top: {
          style: 'thin',
          color: {rgb: '000000'}
        },
        bottom: {
          style: 'thin',
          color: {rgb: '000000'}
        },
        left: {
          style: 'thin',
          color: {rgb: '000000'}
        },
        right: {
          style: 'thin',
          color: {rgb: '000000'}
        }
      }
      // 设置公共样式
      for (var key in obj) {
        if (obj[key] instanceof Object) {
          if (key === 'A1') {
            obj[key].s = {
              border: borderAll, // 边框样式设置
              fill: {
                // fgColor: { rgb: "CCCCCC" }
              },
              font: {
                sz: 21,
                bold: true,
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center'
              }
            }
            continue;
          }
          const index = key.match(/\d+/g)
          var bold = false
          // 第二行加粗
          if (!isEmpty(index) && index.length > 0 && index[0] === '2') {
            bold = true
          }
          obj[key].s = {
            border: borderAll, // 边框样式设置
            alignment: { // 文字样式设置
              horizontal: 'center', // 字体水平居中
              vertical: 'center', // 垂直居中
              wrapText: 1 // 自动换行
            },
            fill: { //背景色
              // fgColor: {rgb: 'C0C0C0'}
            },
            font: { // 单元格中字体的样式与颜色设置
              // sz: 16,
              color: {
                rgb: '000000'
              },
              bold: bold
            },
            bold: true,
            numFmt: 0
          }
        }

      }
      return obj
    },
    exportToExcel(f) {
      // 创建工作簿并添加工作表
      const workbook = XLSX.utils.book_new();
      var file_name = '费用明细';
      var data = [];
      var data2 = [];
      if (f === 'allMonthList') {
        file_name = '总合计统计表'
        data.push(
            [this.title + file_name, '', '', ''],
            ['月份', '车辆名称', '总营收', '总营收'],
        );
        this.allMonthList.forEach(item => {
          data.push([
            item.month_str,
            item.vehicle_name,
            item.total_revenue,
            item.total_expenditure,
          ])
        })
        data.push(
            ['合计', '-', this.allMonthTotal.total_revenue, this.allMonthTotal.total_expenditure]
        );

        const worksheet = XLSX.utils.aoa_to_sheet(data);
        worksheet['!merges'] = [
          {s: {r: 0, c: 0}, e: {r: 0, c: data[0].length - 1}}
        ]
        worksheet["!cols"] = [//设置列宽度
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
        ];
        XLSX.utils.book_append_sheet(workbook, this.setTableStyle(worksheet), '总合计统计表');
      } else {
        file_name = '营收合计统计表'
        data.push(
            [this.title + file_name, '', '', '', '', '', '', '', '', '', '', ''],
            ['完成日期', '起讫地', '品名', '装货吨位', '卸货吨位', '损耗量', '实结数', '单价', '税金', '收入', '驾驶员', '押运员']
        );
        this.revenueList.forEach(item => {
          data.push([
            item.complete_times,
            item.xxxxx_qsd,
            item.xxxxx_pm,
            item.zhsl,
            item.xhsl,
            item.xxxxx_loss,
            item.xhsl,
            item.xxxxx_price,
            item.xxxxx_taxes,
            item.xxxxx_income,
            item.driver ? item.driver.personnel_name : '',
            item.supercargo ? item.supercargo.personnel_name : '',
          ])
        })
        data.push(
            ['合计', '-', '-', this.revenueTotal.zhsl, this.revenueTotal.xhsl, this.revenueTotal.xxxxx_loss, this.revenueTotal.xhsl, this.revenueTotal.xxxxx_price, this.revenueTotal.xxxxx_taxes, this.revenueTotal.xxxxx_income, '-', '-']
        );
        // 将数据转换为工作表
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        worksheet['!merges'] = [
          {s: {r: 0, c: 0}, e: {r: 0, c: data[0].length - 1}}
        ]
        worksheet["!cols"] = [//设置列宽度
          {wpx: 110},
          {wpx: 200},
          {wpx: 200},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
        ];
        XLSX.utils.book_append_sheet(workbook, this.setTableStyle(worksheet), file_name);

        file_name = '成本合计统计表'

        var fnames = this.fixed_expenses_list.map(item => item.name)
        var famounts = this.fixed_expenses_list.map(item => item.amount)
        var enames = this.extra_expenses_list.map(item => item.name)
        var eamounts = this.extra_expenses_list.map(item => item.amount)

        data2.push(
            [this.title + file_name],
            ['月份', '加油费用', '加油公里数', '保养费用', '轮胎费用', '尿素费用', '已申领工具费用', '装车/磅费', '修车/洗车费用', '其他费用', '路费', ...fnames, ...enames]
        );
        this.list.forEach(item => {
          if (item.ck_refuel_amount || item.maintenance_amount || item.tire_amount || item.urea_amount || item.kilometers || item.tool_amount || item.ov_zcbf || item.ov_xcxc || item.ov_qt) {
            data2.push([
              item.curr_yearmonth,
              item.ck_refuel_amount,
              // item.ykxj_refuel_amount,
              item.kilometers,
              item.maintenance_amount,
              item.tire_amount,
              item.urea_amount,
              item.tool_amount,
              item.ov_zcbf,
              item.ov_xcxc,
              item.ov_qt,
              0,
              ...this.fixed_expenses_list.map(item => {
                return 0
              }),
              ...this.extra_expenses_list.map(item => {
                return 0
              }),
            ])
          }
        })
        data2.push(
            [
              '合计',
              this.coseTotal.ck_refuel_amount,
              '-',
              this.coseTotal.maintenance_amount,
              this.coseTotal.tire_amount,
              this.coseTotal.urea_amount,
              this.coseTotal.tool_amount,
              this.coseTotal.ov_zcbf,
              this.coseTotal.ov_xcxc,
              this.coseTotal.ov_qt,
              this.travel_amount,
              ...famounts,
              ...eamounts,
            ]
        );

        // 将数据转换为工作表
        const worksheet2 = XLSX.utils.aoa_to_sheet(data2);
        worksheet2['!merges'] = [
          {s: {r: 0, c: 0,}, e: {r: 0, c: data2[1].length - 1}}
        ]
        worksheet2["!cols"] = [//设置列宽度
          {wpx: 110},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          {wpx: 100},
          ...this.fixed_expenses_list.map(item => {
            return {wpx: 100}
          }),
          ...this.extra_expenses_list.map(item => {
            return {wpx: 100}
          }),
        ];
        XLSX.utils.book_append_sheet(workbook, this.setTableStyle(worksheet2), file_name);
      }

      // 生成Excel文件
      const excelBuffer = XLSXStyle.write(workbook, {bookType: 'xlsx', type: 'buffer'});

      // 使用blob和FileReader创建一个Blob URL
      const dataBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
      const blobUrl = window.URL.createObjectURL(dataBlob);

      // 使用saveAs下载文件
      saveAs(dataBlob, this.title + file_name + '.xlsx');
      // 清理
      window.URL.revokeObjectURL(blobUrl);
    },

    handleExcel() {
      this.excelLoading = true
      Api.costList({...this.queryParam, excel: 1})
          .then(({data: {list}}) => {
                this.downloadFile(list)
                this.excelLoading = false
              }
          )
    },
    downloadFile(url) {
      try {
        let elementIftrame = document.createElement("iframe");
        elementIftrame.src = url;
        elementIftrame.style.display = "none";
        document.body.appendChild(elementIftrame);
      } catch (error) {
        console.log(error);
      }
    },


    open({vehicle_name, month_str, bill_type, profit}) {
      this.bill_type = bill_type
      this.profit = profit

      this.title = vehicle_name + ' - ' + month_str
      this.visible = true

      this.getData()
    },

    getData() {
      if (this.bill_type === 0) {
        this.getTotalRevenue()
        this.getTotalCost()
      } else {
        this.getAllMonthTotalList()
      }
    },

    async getTotalRevenue() {
      this.totalRevenueLoading = true
      const {data: {list}} = await Api.getTotalRevenue(this.queryParam)
      this.revenueList = list.list
      this.revenueTotal = list.total
      this.totalRevenueLoading = false
    },

    async getTotalCost() {
      this.totalCoseLoading = true
      const {data: {list}} = await Api.getTotalCost(this.queryParam)
      this.list = list.list
      this.coseTotal = list.total
      this.fixed_expenses_list = list.fixed_expenses_list
      this.extra_expenses_list = list.extra_expenses_list
      this.travel_amount = list.travel_amount
      this.totalCoseLoading = false
    },

    async getAllMonthTotalList() {
      this.allMonthLoading = true
      const {data: {list}} = await Api.getAllMonthTotalList(this.queryParam)
      this.allMonthList = list.list
      this.allMonthTotal = list.total
      this.allMonthLoading = false
    },


    cancel() {
      this.visible = false
      this.showTotalCoseLog = false
      this.showTotalRevenueLog = false
    },

    /**
     * 编辑固定开支
     */
    handleEditExtraExpenses() {
      const saveData = {
        vb_id: this.queryParam.vb_id,
        extra_expenses_list: this.extra_expenses_list
      }
      this.$refs.EditFixedExpenses.open(saveData)
    },

    /**
     * 编辑路费
     */
    editTravelAmount() {
      const saveData = {
        vb_id: this.queryParam.vb_id,
        travel_amount: this.travel_amount
      }
      this.$refs.EditTravelAmountModel.open(saveData)
    },

    /**
     * 编辑订单装卸货数量
     */
    editOvNumber(item, field) {
      this.$refs.EditOrderVehicle.open(item)
    },
    /**
     * 编辑订单单价
     */
    editOvPrice(item) {
      this.$refs.EditOrderVehiclePriceModel.open(item)
    }
  }
}
</script>
<style scoped lang="less">

.table {
  width: 100%;
  border: 1px solid #e8e8e8;
  padding: 10px;
  margin-bottom: 10px;
  overflow: scroll;

  table {
    width: 100%;
    border-color: #e8e8e8;

    .title {
      text-align: center;
      font-weight: bold;
    }

    td {
      padding: 0
    }
  }

}

.descriptionTxt {
  width: 220px;
  word-wrap: break-word;
  padding: 2px !important;
  white-space: pre-wrap;
}

.tj {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 12.5%;
  }
}
</style>
