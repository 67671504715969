<template>
  <a-modal
      :title="title"
      :width="520"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="单价" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              placeholder="请输入单价"
              v-decorator="['price', {rules: [{required: true, message: '请输入单价'}]}]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/order/vehicle'
import {UploadFile} from '@/components'

export default {
  components: {
    UploadFile
  },
  data() {
    return {
      // 对话框标题
      title: '编辑单价',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
    }
  },
  methods: {
    open(record) {
      this.visible = true
      this.record = record
      this.$nextTick(() => {
        this.form.setFieldsValue({price: record.xxxxx_price})
      })
    },
    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      Api.edit({ovId: this.record['ov_id'], form: values}).then((result) => {
        // 显示成功
        this.$message.success('操作成功', 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }
  }
}
</script>
<style lang="less" scoped>
.table {
  border: 1px #e8e8e8 solid;
  margin-top: 10px;

  .tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d9d9d9 solid;
    line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      width: 100%;
      text-align: center;
      border-right: 1px #d9d9d9 solid;


      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
