<template>
  <a-row :gutter="10" style="margin-top: 10px">
    <a-form :layout="formLayout" labelAlign="left" :form="searchForm" @submit="handleSubmit">
      <a-col :span="8">
        <a-form-item label="加注地点" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
              allowClear
              show-search
              v-decorator="['jyd']"
              placeholder="请选择加注地点"
          >
            <a-select-option  :value="0">仓库</a-select-option>
            <a-select-option  :value="1">其他</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="缴费类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
              allowClear
              show-search
              v-decorator="['jflx']"
              placeholder="请选择缴费类型"
          >
            <a-select-option  :value="0">尿素卡</a-select-option>
            <a-select-option  :value="1">其他</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-button style="margin-right: 10px" type="primary" html-type="submit" icon="search">搜索</a-button>
          <a-button @click="handleReset">重置表单</a-button>
        </a-form-item>
      </a-col>

    </a-form>
  </a-row>
</template>
<script>
import {SelectRegion} from '@/components'

export default {
  name: 'searchForm',
  components: {
    SelectRegion
  },
  data() {
    return {
      searchForm: this.$form.createForm(this),
      queryParam: {},
      formLayout: 'horizontal',
      labelCol: {
        style: 'width: 70px;float:left;margin-right:10px;white-space: nowrap;\n' +
            'text-overflow: ellipsis;\n' +
            '-o-text-overflow: ellipsis;\n' +
            'overflow: hidden;'
      },
      wrapperCol: {style: 'width: calc( 95% - 70px );float:left'},
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      const {searchForm: {validateFields}} = this
      validateFields((errors, values) => {
        if (errors) {
          return false
        }
        this.$emit('handleSubmit', values)
      })
    },
    handleReset() {
      this.searchForm.resetFields()
    }
  }
}
</script>
<style scoped lang="less">

</style>
