<template>
  <a-card :bordered="false">
    <div class="con">
      <div class="head">
        <div class="card-title">选择车辆</div>
        <a-input-search
            allowClear
            v-model="queryParam.keyword"
            placeholder="请输入车牌号码"
            @search="onSearch"
        />
      </div>
      <div style="margin: 5px 0">车辆列表：</div>
      <div class="main">
        <a-spin :spinning="confirmLoading">
          <div class="list" v-if="list.length">
            <div @click="setActionVehicle(item)"
                 :class="['list-item',actionVehicle.vehicle_id==item.vehicle_id?'action':'']" v-for="item in list">
              {{ item.vehicle_name }}
            </div>
          </div>
          <a-empty v-else/>
        </a-spin>
      </div>
    </div>

  </a-card>
</template>
<script>
import * as Api from '@/api/vehicle'

export default {
  components: {},
  data() {
    return {
      queryParam: {
        keyword: ''
      },
      list: [],
      actionVehicle: {},
      confirmLoading: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      this.confirmLoading = true
      const {data: {list}} = await Api.enableList(this.queryParam)
      this.list = list
      this.confirmLoading = false
    },
    onSearch(e) {
      this.getList()
    },
    setActionVehicle(item) {
      this.actionVehicle = item
      this.$emit('success', this.actionVehicle)
    }
  },
}

</script>
<style scoped lang="less">
.con {
  height: calc(100vh - 140px);
  background: #FFFFFF;
  flex-direction: column;
  display: flex;
}

.head {
  flex-grow: 1;
}

.main {
  height: 100%;
  flex-grow: 1;
  overflow: scroll;
}

.list {
  border: 1px solid #e8e8e8;

  .list-item {
    font-weight: bold;
    border-bottom: 1px solid #e8e8e8;
    padding: 8px;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background: #f4f4f4;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .action {
    background: #378DDB !important;
    color: #FFFFFF;
  }
}
</style>
